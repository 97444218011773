import React from 'react';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import styles from './index.module.less';

const Loading = () => {
  const antIcon = <LoadingOutlined style={{ fontSize: 28 }} spin />;
  return (
    <div className={styles.loadingWrap}>
      {/* tip="加载中..." */}
      <Spin indicator={antIcon} size="large"  delay={100}>
        <div className="content" />
      </Spin>
    </div>
  );
};

export default Loading;
