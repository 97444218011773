import request from '../utils/request';
import { getAppId } from '@/utils/index';

// 市场数据相关

/**
 * 核心域根据条件获取首条记录
 * @param {string}  phone
 * @returns
 */
export const getRegion = (phone) => {
  return request.get(`/api/v1.0/app/{app-id}/region/app-customer`, {
    params: {
      phone,
      // agentId,
    },
  });
};

/**
 * 查询话单相关枚举值
 * @param {string}  telTransferState:转接情况,schoolIntention:意向校区,studentType:学员类型,telLevel:呼叫级别,telLevelType:呼叫级别类型,grade:年级
 * @returns
 */
export const getCallRecordMetaData = (name) => {
  return request
    .get(`/api/v1.0/app/{app-id}/meta-data?region=call_record&name=${name}`)
    .then((res) => {
      return res.data;
    });
};

/**
 * 查询投放数据相关枚举值
 * @param {string}  type:类型
 * @returns
 */
export const getDeliveryDataMetaData = (name) => {
  return request
    .get(`/api/v1.0/app/{app-id}/meta-data?region=ad_record&name=${name}`)
    .then((res) => {
      return res.data;
    });
};

/**
 * 分页查询话单
 * @param {object} data
 * @data
 * @param  {number}   ?pageIndex
 * @param  {number}   ?pageSize
 * @param  {string}   ?orderBy            排序字段
 * @param  {string}   ?orderDirection     排序类型
 * @param  {string}   ?qry                综合查询框,手机号 姓名 微信号
 * @param  {string}   ?remark             描述，备注
 * @param  {number}   ?schoolIntention    意向校区
 * @param  {number}   ?status             分配状态 0未分配 1已分配
 * @param  {number}   ?studentType        学员类型
 * @param  {number}   ?telLevel           电话级别
 * @param  {string}   ?createDate         年级
 * @param  {number}   ?campusId           校区ID
 * @param  {number}   ?branchOfficeId     分公司ID
 * @param  {number}   ?allotId            咨询师ID
 * @param  {number}   ?appMemberId        接线座席id
 * @param  {number}   ?belongId           归属人ID
 * @returns {array}
 */
export const getCallRecordsPage = (data) => {
  // 20240625 应赵大海要求，改为v2.0
  return request.post(`/api/v2.0/app/{app-id}/call-records`, {
    data: {
      ...data,
    },
  });
};

/**
 * 根据id查询话单详情
 * @param  {string}   callRecordId      话单id
 * @returns {CallRecordModal}
 */
export const getCallRecordInfo = (callRecordId) => {
  return request.get(`/api/v1.0/app/{app-id}/call-record/${callRecordId}`);
};

/**
 * 保存话单基本信息
 * @param {object} data
 * @data
 * @param  {number}   callId            祥云话单id
 * @param  {string}   callPhone         呼叫手机号
 * @param  {number}   ?channelId        一级渠道id
 * @param  {number}   ?channelId2       二级渠道id
 * @param  {number}   ?type             话单类型 0呼入 1呼出
 * @param  {number}   appMemberId       成员id
 * @param  {string}   appMemberName     成员姓名
 * @returns {CallRecordModal}
 */
export const addCallRecord = (data) => {
  return request.post(`/api/v1.0/app/{app-id}/call-record`, {
    data,
  });
};

/**
 * 修改并创建线索or分配
 * @param {object} data
 * @data
 * @param  {number}            callRecordId      话单id
 * @param  {number}            operateType       操作类型 0保存 1生成线索 2分配
 * @param  {CallRecordModal}   callRecord
 * @returns {result}
 */
export const saveCallRecord = (callRecordId, operateType, callRecord) => {
  return request.put(`/api/v1.0/app/{app-id}/call-record/${callRecordId}`, {
    data: {
      operateType,
      callRecord: {
        ...callRecord,
        id: callRecordId,
      },
    },
  });
};

/**
 * 导出话单
 * @param  {array[number]}   ids         通话记录id集合
 * @returns {ArrayBuffer}
 */
export const exportsCallRecord = (data) => {
  return request.post(`/api/v1.0/app/{app-id}/heracles-task`, {
    data,
  });
};

/**
 * 查询话单录音列表
 * @param  {number} callId   话单id
 */
export const callLogList = (callId) => {
  return request.post(`/api/v1.0/app/${getAppId()}/call-log/${callId}`, {
    data: {
      pageSize: 10000,
      pageIndex: 1,
    },
  });
};

/**
 * 分页查询投放数据
 * @param {object} data
 * @data
 * @param  {number}   ?pageIndex
 * @param  {number}   ?pageSize
 * @param  {string}   ?orderBy            排序字段
 * @param  {string}   ?orderDirection     排序类型
 * @param  {string}   ?qry                综合查询框,手机号 姓名 微信号
 * @param  {string}   ?remark             描述，备注
 * @param  {number}   ?schoolIntention    意向校区
 * @param  {number}   ?status             分配状态 0未分配 1已分配
 * @param  {number}   ?studentType        学员类型
 * @param  {number}   ?telLevel           电话级别
 * @param  {string}   ?createDate         年级
 * @param  {number}   ?campusId           校区ID
 * @param  {number}   ?branchOfficeId     分公司ID
 * @param  {number}   ?allotId            咨询师ID
 * @param  {number}   ?appMemberId        接线座席id
 * @param  {number}   ?belongId           归属人ID
 * @returns {array}
 */
export const getDeliveryDataPage = (data) => {
  // 20240625 应赵大海要求，改为v2.0
  return request.post(`/api/v2.0/app/{app-id}/ad-clue-records`, {
    data: {
      ...data,
    },
  });
};

/**
 * 根据投放数据查询话单录音列表
 * @param  {string} callIds   话单id集合
 */
export const getCallLogListByDeliveryData = (callIds) => {
  return request
    .post(`/api/v1.0/app/{app-id}/call-logs`, {
      data: callIds,
    })
    .then((res) => {
      const data = [];
      if (res?.data) {
        Object.keys(res?.data).forEach((key) => {
          data.push(...res.data[key]);
        });
      }
      res.data = data;
      return res;
    });
};

/**
 * 根据id查询投放数据
 * @param  {string}   callRecordId      话单id
 * @returns {CallRecordModal}
 */
export const getDeliveryDataInfo = (callRecordId) => {
  return request.get(`/api/v1.0/app/{app-id}/ad-clue-record/${callRecordId}`).then((res) => {
    if (res.data.callId) {
      const newCallIds = res.data.callId.split(',').filter((f) => f !== 'null');
      if (newCallIds.length) res.data.callIds = newCallIds;
      res.data.callId = null;
    }
    console.log(res.data);

    return res;
  });
};
/**
 * 投放数据-保存(关闭、生成线索、分配咨询师)
 * @param {object} data
 * @data
 * @param  {number}            callRecordId      话单id
 * @param  {number}            operateType       操作类型 0保存 1生成线索 2分配
 * @param  {CallRecordModal}   callRecord
 * @returns {result}
 */
export const saveDeliveryData = (adClueRecordId, operateType, callRecord) => {
  return request.put(`/api/v1.0/app/{app-id}/ad-clue-record/${adClueRecordId}`, {
    data: {
      operateType,
      appAdClueRecordUpdateDTO: {
        ...callRecord,
        id: adClueRecordId,
      },
    },
  });
};

/**
 * 查询弹屏详情(通话记录/投放数据)
 * @param  {string}   id       id
 * @param  {string}   ?type    cdr/fromAdDelivery
 * @returns {Modal}
 */
export const getPanelInfo = (id, type = 'cdr') => {
  if (type === 'cdr') return getCallRecordInfo(id);
  else return getDeliveryDataInfo(id);
};

/**
 * 投放数据-保存(关闭、生成线索、分配咨询师)
 * @param {object} data
 * @data
 * @param  {number}            callRecordId      话单id
 * @param  {number}            operateType       操作类型 0保存 1生成线索 2分配  3 分配+创建上门
 * @param  {CallRecordModal}   callRecord
 * @CallRecordModal
 * @param  {number}   ?channelId        一级渠道id
 * @param  {number}   ?channelId2       二级渠道id
 * @param  {number}   id                id
 * @param  {number}   allotId           分配咨询师ID
 * @param  {string}   allotName         分配咨询师姓名
 * @param  {number}   appCustomerId     线索ID
 * @param  {number}   belongId          归属人ID
 * @param  {string}   belongName        归属人姓名
 * @param  {number}   branchOfficeId    分公司ID
 * @param  {string}   branchOfficeName  分公司名称
 * @param  {number}   campusId          校区ID
 * @param  {string}   campusName        校区名称
 * @param  {string}   city              城市
 * @param  {string}   county            区县
 * @param  {number}   grade             年级
 * @param  {string}   name              姓名
 * @param  {string}   phone             电话号码
 * @param  {string}   province          省份
 * @param  {string}   remark            描述，备注
 * @param  {number}   schoolIntention   意向校区
 * @param  {number}   status            分配状态 0未分配 1已分配
 * @param  {number}   studentType       学员类型
 * @param  {number}   telLevel          呼叫级别
 * @param  {number}   telLevelType      呼叫级别类型
 * @param  {number}   telTransferState  转接情况
 * @param  {string}   wechatNo          微信号
 * @param  {string}   visitor          上门人 1：孩子及家长 2：家长 3：孩子
 * @param  {string}   visitTime        上门时间
 * @param  {string}   templateCode     短信模板code
 * @returns {result}
 */
export const savePanelDetails = (type = 'cdr', ...args) => {
  if (type === 'cdr') return saveCallRecord(...args);
  else return saveDeliveryData(...args);
};

/**
 * 更新投放数据通话记录id
 * @param {object} data
 * @data
 * @param  {number}            adClueRecordId      投放数据Id
 * @param  {number}            callId              祥云话单id
 * @returns {result}
 */
export const updateAdClueRecordCallId = (adClueRecordId, callId) => {
  return request.put(`/api/v1.0/app/{app-id}/ad-clue-record/${adClueRecordId}/call/${callId}`);
};

/**
 * 导出投放数据
 * @param  {array[number]}   ids         id集合
 * @returns {ArrayBuffer}
 */
export const exportsAdClue = (data) => {
  return request.post(`/api/v1.0/app/{app-id}/heracles-task`, {
    data,
  });
};

/** 获取通话场景枚举 */
export const getCallScene = () => {
  return request.get(`/api/v1.0/app/{app-id}/callScene/enum`);
};

/** 创建通话场景
 * @param {object} data
 * @data
 * @param  {number}   callLineId
 * @param  {string}   callSceneCode
 * @param  {number}   targetId
 * @param  {string}   targetPhone
 * @returns {result}
 */
export const addCallScene = (data) => {
  return request.post(`/api/v1.0/app/{app-id}/callScene`, {
    data,
  });
};

/** 优惠券相关 */

// /**
//  * @typedef {Object} Campus
//  * @property {string} branchCompany - 分公司
//  * @property {string} businessUnit - 事业部
//  * @property {string} campus - 校区
//  * @property {number} status - 校区状态；1:使用中；2：已停用
//  */

// /**
//  * @typedef {Object} ContactRange
//  * @property {number} contactHour - 赠送课时数
//  * @property {string} name - 名称
//  * @property {number} quantity - 券数量
//  * @property {number} rechargeAmountMax - 充值金额max
//  * @property {number} rechargeAmountMin - 充值金额min
//  */

// /**
//  * @typedef {Object} DiscountRange
//  * @property {number} discountRate - 折扣率
//  * @property {string} name - 档位
//  * @property {number} quantity - 券数量
//  * @property {number} rechargeAmountMax - 充值金额max
//  * @property {number} rechargeAmountMin - 充值金额min
//  */

// /**
//  * @typedef {Object} Product
//  * @property {string} categorFirst - 课程一级类目
//  * @property {string} categorySecond - 课程二级类目grade
//  * @property {string} courseID - 课程id
//  * @property {string} courseName - 课程名称
//  * @property {string} coursePrice - 课程售价
//  * @property {number} fitType - 适用类型，1:指定商品；2：指定商品类型;3:all
//  * @property {string} grade - 适用年级
//  */

// /**
//  * @typedef {Object} ReductionRange
//  * @property {string} name - 名称
//  * @property {number} quantity - 券数量
//  * @property {number} rechargeAmountMax - 充值金额max
//  * @property {number} rechargeAmountMin - 充值金额min
//  * @property {number} reductionAmount - 满减金额
//  */

/**
 * @typedef {Object} CouponCampusAddCmd
 * @property {number|null} appID - 业务线id
 * @property {string|null} [appName] - 业务线名称
 * @property {number|null} campusID - 校区id
 * @property {string|null} [campusName] - 校区名称
 * @property {number|null} corpID - 分公司id
 * @property {string|null} [corpName] - 分公司名称
 */

/**
 * @typedef {Object} CouponRangeAddCmd
 * @property {number|null} couponNum - 券数量
 * @property {number|null} discount - 优惠力度（折扣-几折，满减-满减金额，课时-赠送课时）
 * @property {string|null} rangeName - 档位名称
 * @property {number|null} thresholdEnd - 门槛结束（开区间）
 * @property {number|null} thresholdStart - 门槛开始（闭区间）
 */

/** 创建优惠券
 * @param {object} data
 * @property {number|null} operation - 1:创建；2:修改
 * @property {number|null} approvalScene - 审批场景，1:普通优惠；2:特殊优惠
 * @property {CouponCampusAddCmd[]|null} campusAddCmdList - 适用校区
 * @property {string|null} couponName - 券名称
 * @property {number|null} couponType - 优惠券类型，1:折扣券；课时券；现金券
 * @property {string|null} [couponValidityEnd] - 优惠券有效期结束时间
 * @property {string|null} [couponValidityStart] - 优惠券有效期开始时间
 * @property {number[]|null} [goodsCategoryIDList] - 适用的商品类目ids
 * @property {number[]|null} [goodsIDList] - 适用的商品ids
 * @property {number|null} goodsUseType - 商品适用类型，1-指定商品，2-指定商品类型，3-all
 * @property {number|null} isOverlay - 是否叠加使用：1-可叠加；2-不可叠加
 * @property {CouponRangeAddCmd[]|null} rangeAddCmdList - 档位
 * @property {number|null} [receiptValidDays] - 领取后有效天数
 * @property {string|null} strategyValidityEnd - 优惠券规则结束时间
 * @property {string|null} strategyValidityStart - 优惠券规则开始时间
 * @property {number|null} thresholdType - 使用门槛，1:按充值金额；2：按订单金额
 * @property {number|null} validityType - 优惠券有效期类型，1-固定有效期，2-领取后有效期
 */
export const addCoupon = (data) => {
  // return Promise.resolve({
  //   success: true,
  // });
  return request.post(`/api/v1.0/app/{app-id}/coupon-service/strategy`, {
    data,
  });
};

/** 优惠券列表
 * @param {object} data
 * @property {number|null} [auditStatus] - 审批状态，1-待审核，2-审核通过，3-审核驳回
 * @property {number|null} [campusID] - 校区id
 * @property {number|null} [corpID] - 分公司id
 * @property {string|null} [groupBy]
 * @property {boolean|null} [needTotalCount]
 * @property {string|null} [orderBy]
 * @property {string|null} [orderDirection]
 * @property {number|null} [pageIndex]
 * @property {number|null} [pageSize]
 * @property {string|null} [strategyValidityEnd] - 有效期结束时间
 * @property {string|null} [strategyValidityStart] - 有效期开始时间
 */
export const getCouponList = (data) => {
  return request.post(`/api/v1.0/app/{app-id}/coupon-service/strategy/page`, {
    data,
  });
};

/** 优惠详情
 * @param {string} id - 优惠券id
 * @returns {object}
 */
export const getCouponDetail = (id) => {
  return request.get(`/api/v1.0/app/{app-id}/coupon-service/strategy/detail/${id}`);
};

/** 优惠券审批
 * @param {object} data
 * @property {string} strategyId - 规则id
 * @property {number} action - 2-审核通过，3-审核驳回
 */
export const auditCoupon = (data) => {
  return request.post(`/api/v1.0/app/{app-id}/coupon-service/strategy/audit`, {
    data,
  });
};

/** 选择审核人
 * @param {object} data
 * @property {string} strategyId - 规则id
 * @property {number} auditorMemberId - 审批人员id
 * @property {number} studentId - 发券学员id
 */
export const selectAuditor = (data) => {
  return request.post(`/api/v1.0/app/{app-id}/coupon-service/strategy/auditor/pick`, {
    data,
  });
};

/** 查询审核人列表 */
export const getAuditorList = () => {
  return request.get(`/api/v1.0/app/{app-id}/coupon-service/strategy/auditors`);
};

/** 发放记录
 * @param {object} data
 * @property {number|null} [campusID] - 校区id
 * @property {number|null} [corpID] - 分公司id
 * @property {number|null} [couponType] - 优惠券类型，1:折扣券；课时券；现金券
 * @property {string|null} [distTimeEnd] - 发放结束时间
 * @property {string|null} [distTimeStart] - 发放开始时间
 * @property {string|null} [groupBy]
 * @property {string|null} [keyword] - 关键词
 * @property {boolean|null} [needTotalCount]
 * @property {string|null} [orderBy]
 * @property {string|null} [orderDirection]
 * @property {number|null} [pageIndex]
 * @property {number|null} [pageSize]
 * @property {number|null} [useStatus] - 使用状态；1:未使用；2：已发放；3：已使用；4：冻结；5：已过期
 */
export const getSendRecord = (data) => {
  return request.post(`/api/v1.0/app/{app-id}/coupon-service/coupon/page`, {
    data,
  });
};

/** 停用优惠
 * @param {string} id - 优惠券id
 */
export const stopCoupon = (id) => {
  return request.get(`/api/v1.0/app/{app-id}/coupon-service/strategy/disable/${id}`);
};

/**
 * 导出优惠规则
 * @param  {object}   data    同分页查询优惠列表
 * @returns {url}
 */
export const exportCouponList = (data) => {
  return request.post(`/api/v1.0/app/{app-id}/coupon-service/strategy/export`, {
    data,
    responseType: 'blob',
  });
};

/** 导出优惠券发放记录
 * @param {object} data
 */
export const exportSendRecord = (data) => {
  return request.post(`/api/v1.0/app/{app-id}/coupon-service/coupon/export`, {
    data,
    responseType: 'blob',
  });
};

/** 增加优惠券数量
 * @param {object} data
 * @param {string} strategyId - 优惠券id
 * @param {array}  rangeIncrCmdList - 档位
 * @param {number} rangeIncrCmdList.rangeId - 档位id
 * @param {string} rangeIncrCmdList.incrNum - 增加数量
 */
export const addCouponNum = (data) => {
  return request.post(`/api/v1.0/app/{app-id}/coupon-service/strategy/incr-coupon`, {
    data,
  });
};
