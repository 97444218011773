export const FEIGN_DELAY = 2500;

// 数据统计实时刷新时间间隔
export const REFRESH_DELAY = 60 * 1000;

export const UPLOAD_IMG = {
  imageNum: 1,
  videoNum: 1,
  audioNum: 1,
  fileNum: 1,
  recordNum: 1,
};

export const TAB_LIST = [
  {
    label: '跟进',
    value: 'follow',
  },
  {
    label: '通话',
    value: 'call',
  },
]


