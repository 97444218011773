// 判断当前是哪个环境
export const currentEnv = process.env.REACT_APP_ENV;
const ctext = currentEnv !== 'prod' ? `REACT_APP ${currentEnv}` : 'REACT_APP';

// 不要删除，用来识别当前项目环境
// eslint-disable-next-line
console.info(
  `\n %c ${ctext} %c  \n`,
  'color: #fff; background: #03a8e8; padding:5px 0; font-size:12px;font-weight: bold;',
  'background: #03a8e8; padding:5px 0; font-size:12px;',
);

export const isDevEnv = currentEnv === 'dev';
export const isPreEnv = currentEnv === 'pre';
export const isTestEnv = currentEnv === 'test';
export const isProdEnv = currentEnv === 'prod';

const scrmBaseUrl = {
  dev: 'https://dev-gat.eduxd.cn',
  // dev: 'https://ojscrm.xueda.com',
  test: 'https://dev-gat.eduxd.cn',
  pre: 'https://ojscrm.xueda.com',
  prod: 'https://ojscrm.xueda.com',
}[currentEnv];

const wxBaseUrl = {
  dev: 'https://dev-parrot.eduxd.cn',
  test: 'https://dev-parrot.eduxd.cn',
  pre: 'https://prod-parrot.xuedaclass.com',
  prod: 'https://prod-parrot.xuedaclass.com',
}[currentEnv];

export const SCRM_BASEURL = scrmBaseUrl;
export const QINIU_BASEURL = scrmBaseUrl;
export const QINIU_IMG_HOST = `https://s.feup.cn/`;
export const WX_BASEURL = wxBaseUrl;

// access_token
export const ACCESS_TOKEN = {
  dev: 'corgi-token-dev-data',
  test: 'corgi-token-test-data',
  test2: 'corgi-token-test2-data',
  pre: 'corgi-token-pre-data',
  prod: `corgi-token-prod-data`,
}[currentEnv];

// 订单域名-h5
export const ORDER_PAY_DOMAIN = {
  prod: 'https://pay.ojscrm.com/',
  pre: 'https://pay.ojscrm.com/',
  dev: 'https://dev-pay.ojscrm.com/',
  test: 'https://dev-pay.ojscrm.com/',
}[currentEnv];

// 活动域名-h5
export const MARKET_DOMAIN = {
  prod: 'https://xdform.xuedaclass.com/',
  pre: 'https://xdform.xuedaclass.com/',
  dev: 'https://dev-market.ojscrm.com/',
  test: 'https://dev-market.ojscrm.com/',
}[currentEnv];
